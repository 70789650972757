<template>
  <div class="row">
    <!-- begin::Overview -->
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-header flex-wrap">
          <div class="card-title">
            <h3 class="card-label">
              Overview
              <span class="text-muted pt-2 font-size-sm d-block">Users that belong to this company.</span>
            </h3>
          </div>

          <div class="card-toolbar" v-if="$auth.hasPermission('companies.users.invite')">
            <router-link to="/users/create" class="btn btn-primary w-175px font-weight-bolder">
              <span class="svg-icon align-middle">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Add user</span>
            </router-link>
          </div>
        </div>

        <div class="card-body">
          <!-- begin::Datatable actions -->
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-12 col-lg">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    @keyup.enter="$refs.datatable.search"
                    @keyup="searchDebounced"
                  >
                  <span>
                    <i>
                      <font-awesome-icon :icon="['fas', 'search']" />
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end::Datatable actions -->

          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"

            :filters="datatable.filters"

            :transform="transform"
          >
            <!-- begin::Name -->
            <div slot="name" slot-scope="props">
              <div v-if="props.rowData.invite_pending">
                <i>
                  <font-awesome-icon :icon="['fas', 'envelope']" />
                </i>
              </div>

              <a v-else class="text-brand">
                {{ (props.rowData.name) }}
              </a>
            </div>
            <!-- end::Name -->

            <!-- begin::Actions -->
            <div slot="actions" slot-scope="props">
              <!-- begin::Information -->
              <user-information-dropdown
                v-if="$auth.hasPermission('user.information.get')"
                :key="`user.${props.rowData._meta.guid}.actions.information`"
                :user="props.rowData"
              />
              <!-- end::Information -->

              <b-dropdown
                v-if="$auth.hasPermission('companies.users.update.permission-group')"
                ref="actions-dropdown"
                variant="clean btn-sm btn-icon"
                class="dropdown-inline mr-2"
                right
                lazy
                no-caret
                boundary="window"
                menu-class="dropdown-menu-md"
                :disabled="props.rowData._meta.guid === $auth.resource._meta.guid"
              >
                <template v-slot:button-content>
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'cog']" />
                  </span>
                </template>

                <template v-slot:default>
                  <ul class="navi flex-column navi-hover py-2">
                    <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                      Choose an action:
                    </li>

                    <template v-if="$auth.isResourceFromIndex('users') && $auth.hasPermission('companies.users.update.permission-group')">
                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="modifyPermissionGroup(props.rowData)">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'key']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Change permission</span>
                        </a>
                      </li>
                    </template>
                  </ul>
                </template>
              </b-dropdown>

              <a
                v-if="$auth.hasPermission('companies.users.remove')"
                :class="['btn btn-sm btn-clean btn-icon', { disabled: props.rowData._meta.guid === $auth.resource._meta.guid }]"
                @click.prevent="removeUserFromCompany(props.rowData)"
                :disabled="props.rowData._meta.guid === $auth.resource._meta.guid"
              >
                <span class="svg-icon">
                  <font-awesome-icon :icon="['fas', 'trash']" />
                </span>
              </a>
            </div>
            <!-- end::Actions -->
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
      </div>
    </div>
    <!-- end::Overview -->

    <!-- begin::Modals -->
    <modify-permission-group-modal
      ref="modifyPermissionGroupModal"
    />
  </div>
</template>

<script>
// Mixins
import { DatatableMixin } from '@vedicium/metronic-vue';

// Libraries
import User from '@/libs/classes/user';
import companyLib, { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

import userInformationDropdown from './components/information.dropdown.vue';
import modifyPermissionGroupModal from './modals/modify-permission-group.modal.vue';

export default {
  mixins: [DatatableMixin],
  components: {
    userInformationDropdown,
    modifyPermissionGroupModal,
  },
  data () {
    return {
      datatable: {
        url: `${Company.uri}/${companyLib.current._meta.guid}/users`,

        fields: [{
          name: 'name',
          title: 'Name',
          sortField: 'firstname',
          titleClass: 'w-300px',
          dataClass: 'w-300px',
        }, {
          name: 'email',
          title: 'Email address',
          sortField: 'email',
          titleClass: 'w-300px',
          dataClass: 'w-300px',
        }, {
          name: 'company_permissions',
          title: 'Permission group',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
          formatter: (companyPermissions) => {
            const permission = (companyPermissions || []).find((companyPermission) => companyPermission.company_guid === companyLib.current._meta.guid, []);
            return this.$tu(`permission_groups.type.${permission.permission_id}.name`) || permission.permission_id || 'Unknown';
          },
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-125px',
          dataClass: 'w-125px',
        }],

        searchFields: ['firstname', 'lastname', 'email'],

        filters: {},
      },
    };
  },

  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Users' }]);
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => {
          const user = new User().merge(row);

          // Add check if invited for company
          const permissions = user.getCompanyPermission(companyLib.current._meta.guid);
          user.invite_pending = (permissions && permissions.invite_pending ? permissions.invite_pending : false);

          return user;
        });
      }
      return response;
    },

    removeUserFromCompany (user = null) {
      if (!user) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Remove user',
        message: `Are you sure that you want to remove <strong>${user.name || user.email}</strong>?<br /><br />Please type the ${user.name ? 'name' : 'email address'} of the user to confirm:`,
        verifyString: user.name || user.email,
        async onSubmit () {
          await vm.$ws.delete(`${Company.uri}/${companyLib.current._meta.guid}/users/${user._meta.guid}`);

          vm.$refs.datatable.refresh({ query: { disable_cache: true } });
        },
      });
    },

    modifyPermissionGroup (user = null) {
      if (!user || !this.$auth.hasPermission('companies.users.update.permission-group')) {
        return;
      }

      const vm = this;
      vm.$eventhub.emit('modals:users:modify-permission-group:open', {
        user,
        company: companyLib.current,
        async onSubmit (permissionID) {
          const permissionGroupItem = user.getCompanyPermission(companyLib.current._meta.guid);
          if (!permissionGroupItem || permissionGroupItem.permission_id === permissionID) {
            return;
          }

          await vm.$ws.put(`${Company.uri}/${companyLib.current._meta.guid}/users/${user._meta.guid}/permission-group`, {
            body: {
              permission_id: permissionID,
            },
          });

          vm.$refs.datatable.refresh({ query: { disable_cache: true } });
        },
      });
    },
  },
};
</script>
