<template>
  <b-dropdown
    v-if="user"
    ref="dropdown"
    variant="clean btn-sm btn-icon"
    class="dropdown-inline"
    right
    lazy
    no-caret
    boundary="window"
    menu-class="dropdown-menu-lg px-8 py-5"
  >
    <template v-slot:button-content>
      <span class="svg-icon">
        <font-awesome-icon :icon="['fas', 'info-circle']" />
      </span>
    </template>

    <template v-slot:default>
      <div class="row">
        <!-- begin::Loading -->
        <div
          v-if="isLoading"
          class="col p-0 loading-container loading-overlap"
        >
          <div class="loading-backdrop rounded" />
          <div class="loading-block">
            <div class="blockui mx-auto w-100">
              <span>Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
        <!-- end::Loading -->

        <div class="col-12 pb-1 border-bottom">
          <span class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary">User information</span>
        </div>

        <!-- begin::User -->
        <div class="col-12 mt-3">
          <div class="row">
             <!-- begin::Authentication - login -->
            <div class="col-12 d-flex align-items-center">
              <div class="symbol symbol-30 symbol-light-info mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-primary">
                    <font-awesome-icon :icon="['fas', 'clock']" />
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <span class="text-dark text-hover-primary">{{ (lastAuthentication ? $moment(lastAuthentication).format('LL') : '?') }}</span>
                <span class="text-muted font-size-xs">Last online</span>
              </div>
            </div>
            <!-- end::Authentication - login -->
          </div>
        </div>
        <!-- end::User -->
      </div>
    </template>
  </b-dropdown>
</template>

<script>
import format from '@/libs/format';
import objectPath from 'object-path';

import User from '@/libs/classes/user';

export default {
  name: 'userInformationDropdown',
  components: {},
  props: {
    user: {
      type: User,
      default: null,
    },
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      userInformation: null,

      format,
    };
  },
  computed: {
    lastAuthentication () {
      return objectPath.get(this.userInformation, 'authentication.last_activity') || undefined;
    },
  },

  mounted () {
    this.$refs.dropdown.$on('show', this.onShow);
    this.$refs.dropdown.$on('hide', this.onHide);
  },
  beforeDestroy () {
    this.$refs.dropdown.$off('show', this.onShow);
    this.$refs.dropdown.$off('hide', this.onHide);
  },

  methods: {
    async onShow () {
      this.$set(this, 'isLoading', true);
      try {
        const information = await this.$ws.get(`${User.uri}/${this.user._meta.guid}/information`);
        this.$set(this, 'userInformation', information);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },

    onHide () {
      this.$set(this, 'isMounted', false);
    },
  },
};
</script>
