<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Modify permission group')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <form class="form" @submit.prevent="submitForm">
        <div class="form-group mb-0">
          <permission-group-form
            ref="permissionGroupForm"

            :document="user"
            :company="company"

            action="EDIT"
            :disabled="isLoadingForm"
          />
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-primary" :disabled="isLoadingForm" @click.prevent="submitForm">Modify</button>
    </template>
  </b-modal>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

import permissionGroupForm from '../components/permission-group.form.vue';

export default {
  mixins: [Mixins.Modal],
  name: 'modifyPermissionGroupModal',
  components: {
    permissionGroupForm,
  },
  data () {
    return {
      options: {},
      isLoadingForm: null,

      user: null,
      company: null,

      form: {
        value: null,
      },
    };
  },
  validations: {
    form: {
      value: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.options && this.options.required === true;
        }),
      },
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:users:modify-permission-group:open', this.open);
    this.$eventhub.on('modals:users:modify-permission-group:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:users:modify-permission-group:open', this.open);
    this.$eventhub.off('modals:users:modify-permission-group:close', this.close);
  },

  methods: {
    async validate () {
      await validations.validateArray([this.$refs.permissionGroupForm.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.$refs.permissionGroupForm.companyPermissionID);
        }
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      if (!options || !options.user) {
        throw new Error('User undefined');
      }

      if (!options.company) {
        throw new Error('Company undefined');
      }

      // Reset options
      this.$set(this, 'options', options || {});

      // Set user & company
      this.$set(this, 'user', this.options.user.clone());
      this.$set(this, 'company', this.options.company.clone());

      // Reset form
      this.$v.$reset();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    async onShown () {
      await this.$nextTick();
    },
  },
};
</script>
